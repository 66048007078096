import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "styled-components/macro";
import { css } from "styled-components/macro";

import { PrimaryButton } from "../Buttons";
import { SectionHeading } from "../Headings";

export const HeadingRow = tw.div`flex`;
export const Heading = styled(SectionHeading)`
  ${tw`text-blue-800 font-bold`}
`;
export const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
export const PostContainer = styled.div<{ featured: boolean }>`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
export const Post = styled.div`
  ${tw`cursor-pointer flex flex-col bg-gray-100 rounded-lg`}
`;
export const Image = styled.div<{ imageSrc: string }>`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
export const Info = styled.div`
  ${tw`p-8 border-2 border-t-0 rounded-lg rounded-t-none`}
`;
export const Category = tw.div`uppercase text-blue-600 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-blue-600 after:w-8`;
export const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
export const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-blue-800 transition duration-300`;
export const Description = styled.div``;

export const ButtonContainer = tw.div`flex justify-center`;
export const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
