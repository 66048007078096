import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import tw from "twin.macro";

import { BackgroundAsImageWithCenteredContent } from "./BackgroundAsImageWithCenteredContent";
import { Subheading } from "./Headings";

const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

export const PageHeaderWithImage: React.FC<{
  imageSrc: string;
  heading: string;
  subHeading: string;
}> = ({ imageSrc, heading, subHeading }) => {
  return (
    <BackgroundAsImageWithCenteredContent image={imageSrc}>
      <Content>
        <Heading>{heading}</Heading>
        <Subheading tw="text-gray-200">{subHeading}</Subheading>
      </Content>
    </BackgroundAsImageWithCenteredContent>
  );
};
