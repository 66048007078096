import React, { PropsWithChildren } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";

const Container = styled.div<{ image: string }>`
  ${tw`relative -mx-8 bg-center bg-cover h-144`}
  ${({ image }) =>
    css`
      background-image: url("${image}");
    `};
`;

const OpacityOverlay = tw.div`absolute inset-0 bg-black opacity-40`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 text-gray-100 hocus:text-gray-200 focus:outline-none`;

export const BackgroundAsImageWithCenteredContent: React.FC<PropsWithChildren<{ image: string, className?: string }>> = ({
  children,
  className,
  image
}) => {
  return (
    <Container className={className} image={image}>
      <OpacityOverlay />
      <HeroContainer>{children}</HeroContainer>
    </Container>
  );
};
