import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import slugify from "slugify";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  SearchBoxProps,
  connectHits,
} from "react-instantsearch-dom";
import { Search } from "../Search";

import { Container, ContentWithPaddingXl } from "../LayoutHelpers";
import { SectionHeading } from "../Headings";
import { PrimaryButton } from "../Buttons";
import Layout from "../Layout";
import { navigate, PageProps } from "gatsby";
import { HotelType, JobType } from "../../Common";
import { PageHeaderWithImage } from "../PageHeaderWithImage";
import { GenerateJobSlug } from "../../Common/Functions/GenerateSlugs";
import {
  HeadingRow,
  Image,
  Heading,
  Posts,
  PostContainer,
  Post,
  Category,
  Title,
  Description,
  Info,
  CreationDate,
} from "./PageHelpers";
import {
  createURL,
  searchStateToUrl,
  urlToSearchState,
} from "../../Common/Functions/SearchUrlFunctions";

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const LoadingSpinner = () => (
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
);

const searchClient = algoliasearch(
  "KNCLKITPXE",
  "d9bcf23e4a57727ad3f1e3769774d85e"
);
const DEBOUNCE_TIME = 400;

const SearchHits: React.FC<{ hits: any[] }> = ({ hits: jobs }) => {
  return (
    <Posts>
      {jobs.map((job, index) => (
        <PostContainer key={index} featured={false}>
          <Post
            className="group"
            as="a"
            href={`/${GenerateJobSlug(job.hotel.name, job.name)}`}
          >
            <Image imageSrc={job.image.filename} />
            <Info>
              <Category>{job.hotel.name}</Category>
              <CreationDate>{job.start_date || "Ab Sofort"}</CreationDate>
              <Title>{job.name}</Title>
            </Info>
          </Post>
        </PostContainer>
      ))}
    </Posts>
  );
};

const CustomHits = connectHits<JobType>(SearchHits);

export const AllJobs: React.FC<
  PageProps<any, { headingText: string; jobs: JobType[]; hotel: HotelType }>
> = ({ pageContext: { headingText, jobs, hotel } }) => {
  const [searchState, setSearchState] = useState({ query: "" });
  const debouncedSetStateRef = useRef<NodeJS.Timeout>();

  function onSearchStateChange(updatedSearchState: any) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      if (window) {
        navigate(searchStateToUrl(updatedSearchState));
      }
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    if (window) {
      setSearchState(urlToSearchState(window.location) as any);
    }
  }, []);

  return (
    <Layout>
      <Container>
        {hotel && (
          <PageHeaderWithImage
            heading={hotel.name}
            imageSrc={hotel.image.filename}
            subHeading={hotel.city}
          />
        )}
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <InstantSearch
            searchClient={searchClient}
            indexName="prod_dehoga"
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
          >
            <SearchBox
              tw="py-8"
              showLoadingIndicator
              searchAsYouType={false}
              loadingIndicator={<LoadingSpinner />}
              translations={{ placeholder: "Stelle finden..." }}
            />
            {searchState.query && (
              <span tw="text-lg font-normal">Suchergebnisse für "<span tw="font-bold">{searchState.query}</span>"</span>
            )}
            <CustomHits />
          </InstantSearch>
          {/* <Search /> */}
          {!searchState.query && (
            <Posts>
              {jobs.map((job, index) => (
                <PostContainer key={index} featured={index === 0}>
                  <Post
                    className="group"
                    as="a"
                    href={`/${GenerateJobSlug(job.hotel.name, job.name)}`}
                  >
                    <Image imageSrc={job.image.filename} />
                    <Info>
                      <Category>{job.hotel.name}</Category>
                      <CreationDate>
                        {job.start_date || "Ab Sofort"}
                      </CreationDate>
                      <Title>{job.name}</Title>
                      {index === 0 && job.description && (
                        <Description>{job.description}</Description>
                      )}
                    </Info>
                  </Post>
                </PostContainer>
              ))}
            </Posts>
          )}
        </ContentWithPaddingXl>
      </Container>
    </Layout>
  );
};

export default AllJobs;
